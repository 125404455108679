<template>
    <b-overlay :show="loading">
        <form-gudang @onChooseGudang="submitFormGudang"></form-gudang>
        <section class="d-flex justify-content-between align-items-center mb-2">
            <h2>Detail Retur Konsumen</h2>
            <b-button v-if="retur.selesai > 0 && !canApprove" variant="outline-success" @click.prevent="openFormGudang">Kirim ke Gudang</b-button>
        </section>
        <b-card>
            <b-row>
                <b-col sm="6" md="3">
                    <b-form-group label="Nota Penjualan">
                        <strong>{{ retur.penjualan ? retur.penjualan.no_kwitansi : '-' }}</strong>
                    </b-form-group>
                    <b-form-group label="Tanggal Retur">
                        <strong>{{ retur.tanggal }}</strong>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="3">
                    <b-form-group label="Konsumen">
                        <strong>
                            {{ retur.konsumen ? retur.konsumen.nama_toko : '-' }}
                        </strong>
                    </b-form-group>
                    <b-form-group label="Status Retur">
                        <b-badge variant="success" v-if="retur.selesai > 0">
                            SELESAI
                        </b-badge>
                        <b-badge variant="danger" v-else>
                            BELUM SELESAI
                        </b-badge>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="3">
                    <b-form-group label="Sales">
                        <strong>
                            {{ retur.sales ? retur.sales.nama_lengkap : '-' }}
                        </strong>
                    </b-form-group>
                    <b-form-group label="Keterangan">
                        <strong>
                            {{ retur.keterangan }}
                        </strong>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="3">
                    <span class="d-block mb-1 text-center">Total Retur</span>
                    <h2 class="text-center text-danger">
                        <strong>
                            Rp {{ formatRupiah(totalHarga) }}
                        </strong>
                    </h2>
                </b-col>
            </b-row>
        </b-card>
        <!-- rincian -->
        <div class="d-flex justify-content-between align-items-center mb-1">
            <h2>Rincian Retur ({{ retur.rincian ? retur.rincian.length : 0 }})</h2>
            <b-button variant="outline-danger" v-if="checkedBarangs.length > 0" @click.prevent="massRemove">Hapus</b-button>
        </div>
        <b-card>
            <validation-observer ref="form-barang-retur">
                <b-table responsive striped bordered :fields="fields" :items="retur.rincian">
                    <template #table-colgroup="{fields}">
                        <col
                            v-for="field in fields"
                            :key="field.key"
                            :style="{ width: field.key == 'no' ? '10%' : 'auto', padding: '0.72rem'}"
                        />
                    </template>
                    <template #cell(checkbox)="{item}">
                        <b-form-checkbox v-model="checkedBarangs" :value="item"></b-form-checkbox>
                    </template>
                    <template #cell(no)="{index}">
                        {{ index + 1 }}
                    </template>
                    <template #cell(barang)="{item}">
                        {{ item.barang ? item.barang.nama : '-' }}
                    </template>
                    <template #cell(harga_jual)="{item}">
                        {{ `Rp ${formatRupiah(item.harga_jual)}` }}
                    </template>
                    <template #cell(stoks)="{item}">
    
                        {{ item.stoks && item.stoks.length > 0 ? getTotalStok(item.stoks) : 0 }} {{item.barang && item.barang.satuan ? item.barang.satuan.satuan.toLowerCase() : ''}}
                    </template>
                    <template #cell(qty)="{item, index}">
                        <validation-provider #default="{ errors }" name="tanggal_waktu" :rules="`max_value:${item.qty}`" v-if="editAction">
                            <b-form-input @keyup="onChangeQty(index)" :state="errors.length > 0 ? false : null" type="number" v-model="item.custom_qty"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span v-else>{{item.qty}}</span>
                    </template>
                    <template #cell(satuan)="{index, item}">
                        <b-form-select @change="onChangeKonversi(index)" v-model="item.selectedKonversi" v-if="editAction && isHaveKonversi(item.barang) && getTotalStok(item.stoks) > 0" :options="getKonversi(item.barang)" label="text">
                        </b-form-select>
                        <span v-else>
                            {{ item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-' }}
                        </span>
                    </template>
                    <template #cell(converted)="{item}">
                        {{ item.converted ? item.converted : item.qty }} /{{ satuanTerkecil(item) }}
                    </template>
                    <template #cell(total_harga)="{item}">
                        {{ `Rp ${formatRupiah( parseInt(item.harga_jual) * parseInt(item.qty) )}` }}
                    </template>
                    <template #cell(stocks)="{toggleDetails, item}">
                        <b-button v-if="!item.placed_at || item.placed_at.length < 1" variant="primary" size="sm"  @click.prevent="toggleDetails">Pilih Stok</b-button>
                        <b-button v-else variant="primary" size="sm" @click.prevent="toggleDetails">Detail Penempatan</b-button>
                    </template>
                    <template #row-details="{item, toggleDetails}">
                        <b-card>
                            <table v-if="!item.placed_at|| item.placed_at.length < 1">
                                <tr>
                                    <th>#</th>
                                    <th>Gudang</th>
                                    <th>Blok</th>
                                    <th>Palet</th>
                                    <th>Rak</th>
                                    <th>Stok</th>
                                    <th>Stok Disetujui Gudang</th>
                                </tr>
                                <tr :key="i" v-for="(stock, i) in item.stocks">
                                    <td>
                                        <b-form-checkbox v-model="stock.selected"></b-form-checkbox>
                                    </td>
                                    <td>
                                        {{ stock.gudang ? stock.gudang.nama_gudang : '-' }}
                                    </td>
                                    <td>{{ stock.blok ? `Block ${stock.blok.blok}` : '-' }}</td>
                                    <td>{{ stock.palet ? `Palet ${stock.palet.palet}` : '-' }}</td>
                                    <td>{{ stock.rak ? `Rak ${stock.rak.rak}` : '-' }}</td>
                                    <td>{{ stock.stok }}</td>
                                    <td>
                                        <!-- <validation-provider v-if="stock.stok > 0" #default="{errors}" :rules="`max_value:${stock.stok}`"> -->

                                        <b-form-input v-model="stock.selectedStock"></b-form-input>
                                        <!-- <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider> -->
                                    </td>
                                </tr>
                            </table>
                            <table v-else>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Blok</th>
                                        <th>Palet</th>
                                        <th>Rak</th>
                                        <th>Laci</th>
                                        <th>Stok</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(stock, i) in item.placed_at" :key="i">
                                        <td>{{ ++i }}</td>
                                        <td>{{ getPenyimpananName(stock?.id_penyimpanan, 'blok') }}</td>
                                        <td>{{ getPenyimpananName(stock?.id_penyimpanan, 'palet') }}</td>
                                        <td>{{ getPenyimpananName(stock?.id_penyimpanan, 'rak') }}</td>
                                        <td>{{ getPenyimpananName(stock?.id_penyimpanan, 'laci') }}</td>
                                        <td>{{ stock.jumlah }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-button
                                size="sm"
                                variant="outline-secondary"
                                @click="toggleDetails"
                            >
                                Hide
                            </b-button>
                        </b-card>
                    </template>
                </b-table>
            </validation-observer>
        </b-card>
        <section v-if="retur.selesai == 0 && isSales && retur.rincian.length > 0" class="d-flex justify-content-end">
            <b-button v-if="editAction" class="mr-1" variant="outline-danger" @click.prevent="editAction = false">Batal</b-button>
            <b-button v-else class="mr-1" variant="outline-success" @click.prevent="selesaikan">Tandai Selesai</b-button>
            <b-button v-if="editAction" variant="primary" @click.prevent="submit" :disabled="retur.selesai > 0">Simpan Perubahan</b-button>
            <b-button v-else variant="info" @click.prevent="editAction = true">Edit</b-button>
        </section>
        <section v-if="retur.selesai > 0 && canApprove && retur.rincian.length > 0">
            <b-button @click.prevent="approveStock" variant="primary">Simpan</b-button>
        </section>
        <!-- / -->
    </b-overlay>
</template>
<script>
    import {BOverlay, BFormSelect, BCard, BRow, BCol, BFormGroup, BBadge, BTable, BFormInput, BButton, BFormCheckbox} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from "vee-validate"
    import { required, email, confirmed, password } from "@validations"
    import mixin from './mixin'
    import FormGudang from './components/FormGudangModal.vue'
    export default {
        mixins: [mixin],
        components: {
            FormGudang,
            BOverlay, ValidationProvider, ValidationObserver,
            BCard,BRow, BFormSelect, BCol, BFormGroup, BBadge, BTable, BFormInput, BButton, BFormCheckbox
        },
        watch: {
            allChecked(val) {
                if(val) {
                    this.checkedBarangs = []
                    this.retur.rincian.map(rincian => {
                        this.checkedBarangs.push(rincian)
                    })
                }
                else {
                    this.checkedBarangs = []
                }
            }
        },
        data: () => ({
            stocks: [],
            editAction: false,
            allChecked: false,
            checkedBarangs: [],
            loading: false,
            fields: [
                {key: 'barang', label: 'Nama Barang'},
                {key: 'harga_jual', label: 'Harga Satuan'},
                {key: 'stoks', label: 'Stok Mobil'},
                {key: 'qty', label: 'Jumlah'},
                {key: 'satuan', label: 'Satuan'},
                {key: 'converted', label: 'Satuan Konversi'},
                {key: 'total_harga', label: 'Total Harga'}
            ],
            retur: {
                penjualan: null
            },
            swalOptions: {
                title: 'Tandai Selesai Retur Stok?',
                text: `Stok Barang Retur selanjutnya akan ditempatkan ke Gudang yang sudah ditentukan`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            },
            canApprove: false
        }),
        computed: {
            totalHarga() {
                if(!this.retur.rincian) {
                    return 0
                }
                const res = this.retur.rincian.reduce((total, item) => {
                    total += item.converted ? ( parseInt(item.converted) * parseInt(item.harga_jual) ) : ( parseInt(item.custom_qty) * parseInt(item.harga_jual) )
                    return total
                }, 0)
                return res
            }
        },
        methods: {
            getPenyimpananName(penyimpananId, typePenyimpanan = 'blok') {

            },
            approveStock() {
                const sumSelectedStock = []
                const overStock = []
                this.retur.rincian.map(rincian => {
                    const maxStock = rincian.qty
                    const selectedStocks = rincian.stocks.filter(st => st.selected)

                    // push to selected stock 
                    if(selectedStocks.length > 0) {
                        sumSelectedStock.push(...selectedStocks)
                    }

                    // validate for max stok
                    const sumInputStock = selectedStocks.reduce((total, item) => total += parseInt(item.selectedStock), 0)
                    if(sumInputStock > maxStock) {
                        overStock.push(sumInputStock)
                    }

                })

                if(sumSelectedStock.length < 1) {
                    this.displayError({
                        message: 'Harap centang stok barang yang akan ditempatkan'
                    })

                    return false
                }

                if(overStock.length > 0) {
                    this.displayError({
                        message: 'Jumlah stok item tidak boleh melebihi jumlah item yang terjual'
                    })

                    return false
                }
                this.$swal({
                    title: 'Anda yakin?',
                    text: `Pastikan penempatan barang-barang sesuai`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Batal',
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false
                })
                .then(async res => {
                    if(res.value) {

                        const payloads = []
                        this.retur.rincian.map(rincian => {
                            rincian.stocks.map(stock => {
                                if(stock.selected) {
                                    payloads.push({
                                        id_retur_konsumen_rincian: rincian.id,
                                        id_penyimpanan: stock.id,
                                        jumlah: parseInt(stock.selectedStock)
                                    })
                                }
                            })
                        })
        
                        if(payloads.length < 1) {
                            this.displayError({
                                message: 'Harap pilih stok tempat penyimpanan retur!'
                            })
                            return false
                        }
        
                        try {
                            this.loading = true
                            await this.$store.dispatch('retur-konsumen/approve', payloads)
                            this.loading = false
                            this.displaySuccess({
                                message: 'Barang retur ini telah ditempatkan!'
                            })
                        }
                        catch(e) {
                            this.loading = false
                            this.displayError(e)
                            return false
                        }
                    }
                })
            },
            async checkPermissionApprove() {
                const menu = await this.currentMenu(this.$route.meta.parent)
                const params = {
                    level_id: this.user.level.id,
                    menu_id: menu ? menu.id : null
                }
                const status = await this.$store.dispatch('statusrole/getData', params)
                this.canApprove = status.some(item => item.data_status == 1 && !this.isSales && this.isAdminGudang)
            },
            async submitFormGudang(payload) {
                try {
                    this.loading = true
                    this.$bvModal.hide('form-retur-gudang')

                    await this.$store.dispatch('retur-konsumen/lapor', payload)
                    this.loading = false
                    this.displaySuccess({
                        message: 'Data retur telah dikirim ke Gudang yang bersangkutan'
                    })
                    setTimeout(() => this.$router.push('/retur-konsumen'), 1000)
                }
                catch(e) {
                    this.$bvModal.show('form-retur-gudang')
                    this.loading = false
                    this.displayError(e)
                    return false
                }
            },
            async getGudang() {
                const params = {
                    jenis: 1
                }

                const gudangs = await this.$store.dispatch('gudang/getData', params)
                const gudangOptions = gudangs.map(item => ({value: item.id, text: item.nama_gudang}))
                this.$store.commit('retur-konsumen/SET_GUDANG_OPTIONS', gudangOptions)
            },
            openFormGudang() {
                this.$bvModal.show('form-retur-gudang')
            },
            getTotalStok(stocks) {
                if(stocks.length < 1) {
                    return 0
                }
                const hasil = stocks.reduce((total, item) => total += item.stok, 0)
                return hasil > 0 ? hasil : 0
            },
            satuanTerkecil(rincian) {
                if(rincian.barang){
                    return rincian.barang.satuan ? rincian.barang.satuan.satuan : ''
                }
                
                return ''
            },
            async onChangeQty(index) {
                const rincians = Object.assign([], this.retur.rincian)
                const rincian = rincians[index]

                if(rincian.custom_qty != '' && rincian.selectedKonversi) {
                    const konversi = rincian.selectedKonversi
                    const payload = {
                        tipe: 2, // konversi ke satuan terkecil, 1 sebaliknya
                        id_barang: konversi.id_barang,
                        id_satuan: konversi.id_satuan,
                        jumlah: rincians[index].custom_qty
                    }

                    const hasilKonversi = await this.$store.dispatch('konversi/konversiBarang', payload)
                    rincians[index].converted = hasilKonversi

                    // update reactivity
                    this.retur.rincian = rincians
                }
            },
            async onChangeKonversi(indexRincian) {
                const rincians = Object.assign([], this.retur.rincian)
                const rincian = rincians[indexRincian]
                if(rincian.selectedKonversi) {
                    const konversi = rincian.selectedKonversi
                    const payload = {
                        tipe: 2, // konversi ke satuan terkecil, 1 sebaliknya
                        id_barang: konversi.id_barang,
                        id_satuan: konversi.id_satuan,
                        jumlah: rincians[indexRincian].custom_qty
                    }

                    const hasilKonversi = await this.$store.dispatch('konversi/konversiBarang', payload)
                    rincians[indexRincian].converted = hasilKonversi

                    // update reactivity
                    this.retur.rincian = rincians
                }
            },
            isHaveKonversi(barang) {
                return barang.konversi && barang.konversi.length > 0 ? true : false
            },
            getKonversi(barang) {
                const konversi = []
                barang.konversi.map(knv => {
                    knv.value = knv
                    knv.text = knv.satuan ? knv.satuan.satuan : '-'
                    konversi.push(knv)
                })
                
                return konversi
            },
            massRemove() {
                this.swalOptions = {
                    title: 'Anda Yakin?',
                    text: `${this.checkedBarangs.length} barang akan dibatalkan dari Retur`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Batal',
                    customClass: {
                        confirmButton: 'btn btn-outline-success',
                        cancelButton: 'btn btn-danger ml-1',
                    },
                    buttonsStyling: false,
                }

                this.$swal(this.swalOptions)
                .then(async res => {
                    if(res.value) {
                        const payloads = []
                        // set payloads
                        this.checkedBarangs.map(rincianRetur => {
                            payloads.push({
                                id: rincianRetur.id,
                                fungsi: 2
                            })
                        })

                        try {
                            this.loading = true
                            await this.$store.dispatch('retur-konsumen-rincian/save', payloads)
                            await this.getDataRetur()
                            await this.getRetur()
                            this.loading = false
                        }
                        catch(e) {
                            this.loading = false
                            this.displayError(e)
                            return false
                        }
                    }
                })
            },
            selesaikan() {
                this.$swal(this.swalOptions)
                .then(async (res) => {
                    if(res.value) {
                        const payload = {
                            id_retur_konsumen: this.$route.params.id
                        }
                        try {
                            this.loading = true
                            this.editAction = false
                            const response = await this.$store.dispatch('retur-konsumen/checkout', payload)
                            this.displaySuccess({
                                message: 'Harap pilih gudang tujuan penempatan stok retur'
                            })
                            await this.getDataRetur()
                            await this.getRetur()
                            this.loading = false
                        }
                        catch(e) {
                            this.loading = false
                            this.displayError(e)
                            return false
                        }
                    }
                    // else {
                    //     setTimeout(() => {
                    //         this.editAction = false
                    //         this.displaySuccess({message: 'Retur berhasil diperbaharui'})
                    //         this.getRetur()
                    //     }, 500)
                    // }
                })
            },
            // submit rincian retur konsumen
            submit() {
                // validation hasil konversi bisi lebih dari
                const thereOverStock = this.retur.rincian.some(rincian => rincian.converted && parseInt(rincian.converted) > parseInt(rincian.qty))

                if(thereOverStock) {
                    this.displayError({
                        message: 'Jumlah item retur tidak boleh melebihi jumlah yang terjual di penjualan!'
                    })

                    return false
                }
                this.$refs['form-barang-retur'].validate()
                .then(async success => {
                    if(success) {

                        const payloads = []
                        this.retur.rincian.map(rincian => {
                            rincian.id_retur_konsumen = this.$route.params.id
                            const payload = {
                                id: rincian.id,
                                id_retur_konsumen: this.$route.params.id,
                                id_barang: rincian.id_barang,
                                harga_jual: rincian.harga_jual,
                                qty: rincian.custom_qty
                            }
                            if(rincian.converted) {
                                payload.qty = rincian.converted
                            }
                            payloads.push(payload)
                        })
                        try {
                            this.loading = true
                            await this.$store.dispatch('retur-konsumen-rincian/save', payloads)
                            this.loading = false
                            this.selesaikan()
                            
                        }
                        catch(e) {
                            console.error(e)
                            this.loading = false
                            this.displayError(e)
                            return false
                        }
                    }
                })
            },
            async getDataRetur() {
                const params = {order: 'desc'}
                if(this.isSales) {
                    params.id_sales = this.user.karyawan.id
                }
                const returs = await this.$store.dispatch('retur-konsumen/getData', params)
                return returs
            },
            async getRetur() {
                let retur = this.$store.getters['retur-konsumen/search'](this.$route.params.id)
                if(!retur) {
                    const returs = await this.getDataRetur()
                    retur = returs.find(retur => retur.id == this.$route.params.id)
                }

                // get stock
                if(retur.rincian) {
                    retur = await this.getStocks(retur)
                    retur.rincian.map(async rincian => {
                        rincian.qty = parseInt(rincian.qty)
                        rincian.custom_qty = rincian.qty // for changeable
                        rincian.selectedKonversi = null
                        rincian.converted = null
                        rincian.stocks = []
                        rincian.placed_at = null // untuk info penempatan apa bila sudah ditempatkan

                        if(this.isAdminGudang && !this.isSales && this.myGudang) {
                            const stocksPlaced = await this.$store.dispatch('retur-konsumen/getApproved', {
                                id_retur_konsumen_rincian: rincian.id
                            })

                            rincian.placed_at = stocksPlaced
                        }
                    })
                }

                this.retur = retur
            },
            setField() {
                if(this.retur.selesai > 0 && this.canApprove) {
                    this.fields = [
                        {key: 'barang', label: 'Nama Barang'},
                        {key: 'harga_jual', label: 'Harga Satuan'},
                        {key: 'qty', label: 'Jumlah'},
                        {key: 'satuan', label: 'Satuan'},
                        {key: 'total_harga', label: 'Total Harga'},
                        {key: 'stocks', label: '#'}
                    ]
                }
            },
            getPenyimpananName(penyimpananId, type = 'blok') {
                const stock = this.stocks.find(item => item.id === penyimpananId)
                if(!stock) {
                    return '-'
                }

                let penyimpananName = '-'
                switch(type) {
                    case 'blok':
                        const blok = 
                        penyimpananName = stock?.blok?.blok ? `Blok: ${stock?.blok?.blok}` : '-'
                        break
                    case 'palet':
                        penyimpananName = stock?.palet?.palet ? `Palet: ${stock?.palet?.palet}` : '-'
                        break
                    case 'rak':
                        penyimpananName = stock?.rak?.rak ? `Rak: ${stock?.rak?.rak}` : '-'
                        break
                    case 'laci':
                        penyimpananName = stock?.laci?.laci ? `Laci: ${stock?.laci?.laci}` : '-'
                        break
                }

                return penyimpananName
            },
            async getPenyimpanan() {
                if(this.retur.rincian) {
                    // get barang_id
                    const barangsId = []
                    this.retur.rincian.map(rincian => {
                        if(rincian.barang) barangsId.push(rincian.barang.id)
                    })
                    
                    // get stock
                    const params = {
                        gudang_id: this.myGudang.id,
                        barang_id: barangsId.join(',')
                    }
                    const stocks = await this.$store.dispatch('penyimpanan/getData', params)
                    this.stocks = stocks
                    this.retur.rincian.map(rincian => {
                        if(rincian.barang) {
                            const rincianStocks = stocks.filter(stock => stock.barang && stock.barang.id == rincian.barang.id)
                            rincianStocks.map(item => {
                                item.selected = false
                                item.selectedStock = 0
                            })
                            if(rincianStocks.length > 0) {
                                rincian.stocks = rincianStocks
                            }
                        }
                    })
                }
            }
        },
        async created() {
            await this.checkPermissionApprove()
            this.loading = true
            await this.getRetur()
            await this.getGudang()
            if(this.retur.selesai > 0 && this.canApprove) {
                await this.setField()
                this.getPenyimpanan()
            }
            this.loading = false
        }
    }
</script>