export default {
  methods: {
    async getStocks(retur) {
      const mobilId = await this.getMobilId(retur)
      retur.rincian.forEach(async item => {
        const rincian = item
        rincian.stoks = []
        if (rincian.barang) {
          rincian.stoks = await this.$store.dispatch('gudang/getDataStokMobil', {
            order: 'desc',
            id_barang: rincian.barang.id,
            id_gudang: mobilId,
          })
        }
      })
      return retur
    },
    async getMobilId(retur) {
      if (!this.isSales) {
        const mobils = await this.$store.dispatch('gudang/getData', {
          jenis: 2,
          karyawan_id: retur.sales.id,
        })
        if (mobils && mobils.length > 0) {
          return mobils[0].id
        }
      }

      return this.myGudang.id
    },
  },
}
